import Link from "components/ButtonLink";
import { useLocale } from "hooks/useLocale";
import React from "react";
import styled from "styled-components";
import { DatoCmsBanner } from "templates/page";
import { computePathFromLocalizedData } from "utils/common/navigation";
interface BannerProps {
  content: DatoCmsBanner;
}

function Banner({ content }: BannerProps) {
  const { locale, defaultLocale } = useLocale();
  const {
    title,
    backgroundImage,
    backgroundImageMobile,
    link,
    linkLabel,
    textNode,
    marginBottom,
    marginTop,
  } = content;
  return (
    <Container
      image={backgroundImage}
      imageMobile={backgroundImageMobile}
      style={{ marginTop, marginBottom }}
    >
      <Content>
        {title && <Title>{title}</Title>}
        {textNode && (
          <Rte
            dangerouslySetInnerHTML={{
              __html: textNode.childMarkdownRemark.html,
            }}
          />
        )}
        {link && (
          <Link
            to={computePathFromLocalizedData(
              locale,
              defaultLocale,
              link.__typename,
              link.handle
            )}
          >
            {linkLabel}
          </Link>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div<{
  image?: { url: string };
  imageMobile?: { url: string };
}>`
  width: 100%;
  min-height: 400px;
  ${({ image }) =>
    image
      ? `background: url(${image.url}) center center;
      background-size: cover;`
      : ``};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ imageMobile }) =>
    imageMobile
      ? `@media (max-width: 600px) {
    background: url(${imageMobile.url}) center center;
    background-size: cover;
  }`
      : ``};
`;

const Rte = styled.div`
  color: #333;
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.8em;
    margin: 0;
    margin-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 20px;

    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.04em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
  }
  pre {
    white-space: normal;
  }
  img {
    width: 100%;
    display: block;
    margin: 20px 0;
  }
  ul,
  ol {
    li {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.8em;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  blockquote {
    background-color: ${({ theme }) => theme.colors.background2};
    padding: 60px 40px;

    margin: 30px auto;
    box-sizing: border-box;
    position: relative;

    width: 100%;
    &:before,
    &:after {
      content: "“";
      display: block;
      position: absolute;
      color: ${({ theme }) => theme.colors.main};
      opacity: 0.3;
      font-size: 90px;
      font-weight: 400;
    }
    &:before {
      content: "“";
      top: -10px;
      left: 10px;
    }
    &:after {
      content: "”";
      bottom: -50px;
      right: 10px;
    }
    p {
      width: 100%;
      position: relative;
      color: ${({ theme }) => theme.colors.main};
      font-weight: 700;
      padding: 0;
    }
    ul {
      list-style: none;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      text-align: right;
      li {
        color: ${({ theme }) => theme.colors.main};
        font-size: 14px;
        margin-bottom: -20px;
      }
    }
  }
`;

const Content = styled.div`
  padding: 80px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 500px) {
    padding: 80px 20px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
  @media (max-width: 860px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Text = styled.div`
  margin-bottom: 30px;
  color: #333;
  letter-spacing: 0.08em;
`;

export default Banner;
