import React from "react";
import styled from "styled-components";
import { DatoCmsZohoForm } from "templates/page";

interface Props {
  content: DatoCmsZohoForm;
}

const ZohoForm: React.SFC<Props> = ({ content }) => (
  <Container>
    <Title>{content.title}</Title>
    {content.textNode && (
      <Text
        dangerouslySetInnerHTML={{
          __html: content.textNode.childMarkdownRemark.html,
        }}
      />
    )}
    {content.formId && (
      <iframe
        src={`/zoho-forms/${content.formId}`}
        height={content.frameHeight}
        width="100%"
        frameBorder="0"
      />
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
  select {
    margin-bottom: 30px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

export default ZohoForm;
