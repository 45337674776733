import Link from "components/ButtonLink";
import { GatsbyImage } from "gatsby-plugin-image";
import { useLocale } from "hooks/useLocale";
import React from "react";
import { CarouselProvider, Slide, Slider, DotGroup } from "react-carousel";
import styled from "styled-components";
import { HeroRecord } from "templates/page";
import { computePathFromLocalizedData } from "utils/common/navigation";

interface Props {
  content: HeroRecord;
}

const Hero: React.SFC<Props> = ({ content }) => {
  const { locale, defaultLocale } = useLocale();

  const renderImage = () => {
    const { title, subtitle, link, linkLabel, image, imageMobile, imageColor } =
      content.slides[0];
    return (
      <>
        <DesktopImageWrapper imageMobile={imageMobile}>
          <DesktopImage src={image.url} alt={image.alt || ""} />
        </DesktopImageWrapper>
        {imageMobile && (
          <MobileImageWrapper _image={image}>
            <MobileImage src={imageMobile.url} alt={imageMobile.alt || ""} />
          </MobileImageWrapper>
        )}

        <Content>
          {title && <Title imageColor={imageColor}>{title}</Title>}
          {subtitle && <Subtitle imageColor={imageColor}>{subtitle}</Subtitle>}
          {link && (
            <Link
              color={imageColor === "light" ? "main" : "light"}
              to={computePathFromLocalizedData(
                locale,
                defaultLocale,
                link.__typename,
                link.handle,
                link.category && link.category.handle
              )}
            >
              {linkLabel}
            </Link>
          )}
        </Content>
      </>
    );
  };

  const renderGallery = () => {
    return (
      <CarouselProvider totalSlides={content.slides.length}>
        <>
          <Slider>
            {content.slides.map(
              (
                {
                  title,
                  subtitle,
                  link,
                  linkLabel,
                  image,
                  imageMobile,
                  imageColor,
                },
                index: number
              ) => (
                <Slide key={index} index={index}>
                  {index !== 0 ? (
                    <>
                      <DesktopImageWrapper imageMobile={imageMobile}>
                        <DesktopGatsbyImage
                          image={image.gatsbyImageData}
                          alt={image.alt || ""}
                        />
                      </DesktopImageWrapper>
                      {imageMobile && (
                        <MobileImageWrapper _image={image}>
                          <MobileGatsbyImage
                            image={imageMobile.gatsbyImageData}
                            alt={imageMobile.alt || ""}
                          />
                        </MobileImageWrapper>
                      )}
                    </>
                  ) : (
                    <>
                      <DesktopImageWrapper imageMobile={imageMobile}>
                        <DesktopImage
                          src={image.url}
                          srcSet={image.gatsbyImageData.images.fallback?.srcSet}
                          alt={image.alt || ""}
                        />
                      </DesktopImageWrapper>
                      {imageMobile && (
                        <MobileImageWrapper _image={image}>
                          <MobileImage
                            src={imageMobile.url}
                            srcSet={
                              imageMobile.gatsbyImageData.images.fallback
                                ?.srcSet
                            }
                            alt={imageMobile.alt || ""}
                          />
                        </MobileImageWrapper>
                      )}
                    </>
                  )}
                  <Content>
                    {title && <Title imageColor={imageColor}>{title}</Title>}
                    {subtitle && (
                      <Subtitle imageColor={imageColor}>{subtitle}</Subtitle>
                    )}
                    {link && (
                      <Link
                        color={imageColor === "light" ? "main" : "light"}
                        to={computePathFromLocalizedData(
                          locale,
                          defaultLocale,
                          link.__typename,
                          link.handle,
                          link.category && link.category.handle
                        )}
                      >
                        {linkLabel}
                      </Link>
                    )}
                  </Content>
                </Slide>
              )
            )}
          </Slider>
          <DotGroupStyled />
        </>
      </CarouselProvider>
    );
  };

  return (
    <Container>
      {content.slides && content.slides.length === 1
        ? renderImage()
        : renderGallery()}
    </Container>
  );
};

const DotGroupStyled = styled(DotGroup)`
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  bottom: 20px;
  width: 100%;

  button {
    background-color: rgba(0, 0, 0, 0.2);
    height: 8px;
    width: 8px;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    margin: 0 6px;
    &:disabled {
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
const Container = styled.div`
  position: relative;
  height: 70vh;
  overflow: hidden;

  @media (max-width: 600px) {
    height: 600px;
  }
  @media (max-width: 400px) {
    height: 500px;
  }
`;

const DesktopImageWrapper = styled.div<{ imageMobile: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${({ imageMobile }) =>
    imageMobile
      ? `@media (max-width: 600px) {
    display: none;
  }`
      : ""};
`;

const DesktopGatsbyImage = styled(GatsbyImage)`
  min-width: 100%;
  min-height: 100%;
`;

const MobileImageWrapper = styled.div<{ _image: any }>`
  display: none;
  ${({ _image }) =>
    _image
      ? `@media (max-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }`
      : ""};
`;

const MobileGatsbyImage = styled(GatsbyImage)`
  min-width: 100%;
  min-height: 100%;
`;

const DesktopImage = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const MobileImage = styled.img`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  position: absolute;
  bottom: 20%;
  width: 80%;
  left: 10%;
  text-align: center;
  color: #fff;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: ${({ imageColor }: { imageColor: "light" | "dark" }) =>
    imageColor === "light" ? "#333" : "#fff"};
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.06em;
  padding-bottom: 30px;
  color: ${({ imageColor }: { imageColor: "light" | "dark" }) =>
    imageColor === "light" ? "#333" : "#fff"};
`;

export default Hero;
