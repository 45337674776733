import Button from "components/Button";
import InputText from "components/InputText";
import Link from "components/Link";
import Select from "components/Select";
import Spinner from "components/Spinner";
import { graphql, useStaticQuery } from "gatsby";
import { FieldLocale, useLocale } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import { uniqueId } from "lodash";
import React, { ChangeEvent, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { NewsletterRecord } from "templates/page";
import theme from "theme";

interface Props {
  content: NewsletterRecord;
}

interface FormState {
  email: string;
  firstName: string;
  lastName: string;
  birthday: {
    day: number;
    month: number;
    year: number;
  };
  city: string;
  gender: string;
  phone: string;
  numberOfEarPiercing?: string;
}

const NewsletterSection: React.SFC<Props> = ({ content }) => {
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    email: "",
    firstName: "",
    lastName: "",
    birthday: { day: 1, month: 1, year: 1990 },
    city: "",
    phone: "",
    gender: "",
  });

  const [subscriptionResult, setSubscriptionResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useTranslation();
  const { locale, defaultLocale } = useLocale();
  const [language, setLanguage] = useState<string>();

  React.useEffect(() => {
    let language = (window.navigator.language || locale).slice(0, 2);
    setLanguage(language);
  }, [locale]);

  const availableFields = [
    "firstName",
    "lastName",
    "birthday",
    "city",
    "email",
    "gender",
    "phone",
    "numberOfEarPiercing",
  ];

  const fieldList = content.listFields
    .split(",")
    .map((el) => el.trim())
    .filter((el) => availableFields.includes(el));
  const requiredFieldList = content.listRequiredFields
    .split(",")
    .map((el) => el.trim())
    .filter((el) => availableFields.includes(el));

  const isVisible = (element: string) => fieldList.includes(element);
  const isRequired = (element: string) => requiredFieldList.includes(element);

  const { datoCmsGeneral: query } = useStaticQuery(staticQuery);
  const linksPrivacy = query.linkPrivacy._allHandleLocales;

  const localizedUrl = linksPrivacy.find(
    (p: FieldLocale) => p.locale === locale
  ).value;
  const privacyLinkLocalized =
    locale === defaultLocale
      ? `/${localizedUrl}/`
      : `/${locale}/${localizedUrl}`;

  const submitEnabled =
    privacy &&
    availableFields.reduce(
      (a: boolean, f) =>
        isRequired(f) ? a && Boolean(formState[f as keyof FormState]) : a,
      true
    );

  async function submitPreprocessing(e: React.FormEvent<EventTarget>) {
    e.preventDefault();
    const {
      email,
      firstName,
      lastName,
      birthday,
      city,
      gender,
      phone,
      numberOfEarPiercing,
    } = formState;
    if (!submitEnabled) {
      return;
    }
    if (!privacy) {
      setPrivacyError(true);
      return;
    }
    setPrivacyError(false);
    try {
      setIsSubmitting(true);
      const { day, month, year } = birthday;
      const birthdayFormatted = `${year}/${("0" + Number(month)).slice(-2)}/${(
        "0" + Number(day)
      ).slice(-2)}`;

      const body = {
        email,
        firstName,
        lastName,
        birthday: birthdayFormatted,
        listId: content.listId,
        shouldConfirm: false,
        city,
        gender,
        phone,
        ...(numberOfEarPiercing !== "" && { numberOfEarPiercing }),
        language,
      };
      const response = await fetch(
        "/.netlify/functions/newsletter-subscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        switch (data.reason) {
          case "ALREADY_REGISTERED":
            setSubscriptionResult("ALREADY_REGISTERED");
            break;
          default:
            setSubscriptionResult("ERROR");
            break;
        }
      } else {
        (window as any).dataLayer.push({
          event: "gaEvent",
          eventCategory: "Gestione Account",
          eventAction: "Iscrizione Newsletter",
        });

        switch (data.msg) {
          case "SUBSCRIBED":
            setSubscriptionResult("SUBSCRIBED");
            break;
          case "PENDING":
            setSubscriptionResult("PENDING");
            break;
          case "CONTACT_SUPPORT":
            setSubscriptionResult("CONTACT_SUPPORT");
            break;
          default:
            setSubscriptionResult("SUCCESS");
            break;
        }
      }
    } catch (err) {
      setSubscriptionResult("ERROR");
    } finally {
      setIsSubmitting(false);
    }
  }

  const checkboxId = useMemo(() => uniqueId(), []);

  return (
    <Container>
      <Title>{content.title}</Title>
      {content.textNode && (
        <Text
          dangerouslySetInnerHTML={{
            __html: content.textNode.childMarkdownRemark.html,
          }}
        />
      )}
      {!subscriptionResult && (
        <>
          <SignUpForm onSubmit={submitPreprocessing}>
            {isVisible("firstName") && (
              <>
                <Label>
                  <FormattedMessage id="label.firstName" />{" "}
                  {isRequired("firstName") ? "*" : ""}
                </Label>
                <InputText
                  type="text"
                  placeholder={formatMessage({
                    id: "label.firstName",
                  })}
                  value={formState.firstName || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState({ ...formState, firstName: e.target.value })
                  }
                />
              </>
            )}
            {isVisible("lastName") && (
              <>
                <Label>
                  <FormattedMessage id="label.lastName" />{" "}
                  {isRequired("lastName") ? "*" : ""}
                </Label>
                <InputText
                  type="text"
                  placeholder={formatMessage({
                    id: "label.lastName",
                  })}
                  value={formState.lastName || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState({ ...formState, lastName: e.target.value })
                  }
                />
              </>
            )}
            {isVisible("lastName") && (
              <>
                <Label>
                  <span>Email {isRequired("email") ? "*" : ""}</span>
                </Label>
                <InputText
                  type="text"
                  placeholder={formatMessage({
                    id: "label.email",
                  })}
                  value={formState.email || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState({ ...formState, email: e.target.value })
                  }
                />
              </>
            )}

            {isVisible("birthday") && (
              <>
                <Label>
                  <FormattedMessage id="label.birthday" />{" "}
                  {isRequired("birthday") ? "*" : ""}
                </Label>
                <DateContainer>
                  <DateBlock>
                    <Label>
                      <FormattedMessage id="label.birthday.day" />
                    </Label>
                    <Select
                      placeholder={formatMessage({
                        id: "label.birthday.day",
                      })}
                      value={formState.birthday.day}
                      options={[...Array(31).keys()].map((index) => index + 1)}
                      useSystemSelect={true}
                      display={(value: any) => value}
                      valueChange={(value: any) => {
                        setFormState({
                          ...formState,
                          birthday: {
                            ...formState.birthday,
                            day: Number(value),
                          },
                        });
                      }}
                      compare={(value, option) => value === option}
                      systemSelectStyleOverride={{
                        letterSpacing: "0.08em",
                        color: theme.colors.text,
                      }}
                    />
                  </DateBlock>
                  <DateBlock>
                    <Label>
                      <FormattedMessage id="label.birthday.month" />
                    </Label>
                    <Select
                      placeholder={formatMessage({
                        id: "label.birthday.month",
                      })}
                      value={formState.birthday.month}
                      options={[...Array(12).keys()].map((index) => index + 1)}
                      useSystemSelect={true}
                      display={(value) => value}
                      valueChange={(value) =>
                        setFormState({
                          ...formState,
                          birthday: {
                            ...formState.birthday,
                            month: Number(value),
                          },
                        })
                      }
                      compare={(value, option) => value === option}
                      systemSelectStyleOverride={{
                        letterSpacing: "0.08em",
                        color: theme.colors.text,
                      }}
                    />
                  </DateBlock>
                  <DateBlock>
                    <Label>
                      <FormattedMessage id="label.birthday.year" />
                    </Label>
                    <Select
                      placeholder={formatMessage({
                        id: "label.birthday.year",
                      })}
                      value={formState.birthday.year}
                      options={[...Array(100).keys()].map(
                        (index) => 2020 - index
                      )}
                      useSystemSelect={true}
                      display={(value) => value}
                      valueChange={(value) =>
                        setFormState({
                          ...formState,
                          birthday: {
                            ...formState.birthday,
                            year: Number(value),
                          },
                        })
                      }
                      compare={(value, option) => value === option}
                      systemSelectStyleOverride={{
                        letterSpacing: "0.08em",
                        color: theme.colors.text,
                      }}
                    />
                  </DateBlock>
                </DateContainer>
              </>
            )}
            {isVisible("city") && (
              <>
                <Label>
                  <FormattedMessage id="label.city" />{" "}
                  {isRequired("city") ? "*" : ""}
                </Label>
                <InputText
                  type="text"
                  placeholder={formatMessage({
                    id: "label.city",
                  })}
                  value={formState.city || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState({ ...formState, city: e.target.value })
                  }
                />
              </>
            )}
            {isVisible("gender") ? (
              <>
                <Label>
                  <FormattedMessage id="label.gender" />{" "}
                  {isRequired("gender") ? "*" : ""}
                </Label>
                <Select
                  useSystemSelect={true}
                  options={[
                    {
                      value: "Female",
                      label: formatMessage({ id: "label.female" }),
                    },
                    {
                      value: "Male",
                      label: formatMessage({ id: "label.male" }),
                    },
                    {
                      value: "Other",
                      label: formatMessage({ id: "label.other" }),
                    },
                  ]}
                  placeholder={formatMessage({ id: "label.gender" })}
                  display={(value) => value.label}
                  valueChange={(option) => {
                    setFormState({ ...formState, gender: option.value });
                  }}
                  compare={(value, option) => value.value === option}
                />
              </>
            ) : null}

            {isVisible("phone") ? (
              <>
                <Label>
                  <FormattedMessage id="label.phone" />{" "}
                  {isRequired("phone") ? "*" : ""}
                </Label>
                <InputText
                  type="text"
                  placeholder={formatMessage({
                    id: "label.phone",
                    defaultMessage: "Phone",
                  })}
                  value={formState.phone || ""}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormState({ ...formState, phone: e.target.value })
                  }
                />
              </>
            ) : null}

            {isVisible("numberOfEarPiercing") ? (
              <>
                <Label>
                  <FormattedMessage id="label.numberOfEarPiercing" />{" "}
                  {isRequired("numberOfEarPiercing") ? "*" : ""}
                </Label>
                <InputText
                  type="number"
                  placeholder={formatMessage({
                    id: "label.numberOfEarPiercing",
                    defaultMessage: "numberOfEarPiercing",
                  })}
                  min={0}
                  value={
                    formState.numberOfEarPiercing == null
                      ? ""
                      : formState.numberOfEarPiercing
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setFormState({
                      ...formState,
                      numberOfEarPiercing: e.target.value,
                    });
                  }}
                />
              </>
            ) : null}
            <Checkbox hasError={privacyError}>
              <input
                id={checkboxId}
                type="checkbox"
                checked={privacy}
                onChange={() => {
                  setPrivacy((oldState) => !oldState);
                  setPrivacyError(false);
                }}
              />
              <label htmlFor={checkboxId}>
                <FormattedMessage
                  id="label.newsletterAgreement"
                  values={{
                    privacyPolicy: (
                      <Link to={privacyLinkLocalized}>
                        <FormattedMessage id="label.newsletterAgreement.privacyLinkLabel" />
                      </Link>
                    ),
                  }}
                />
              </label>
            </Checkbox>

            <SignUpButton disabled={!submitEnabled} role="submit">
              {!isSubmitting ? (
                <FormattedMessage id="label.signup" />
              ) : (
                <Spinner />
              )}
            </SignUpButton>
          </SignUpForm>
        </>
      )}

      {subscriptionResult === "SUBSCRIBED" && (
        <SuccessContainer>
          <FormattedMessage id="newsletterFormOnlineOffline.success" />
        </SuccessContainer>
      )}
      {subscriptionResult === "PENDING" && (
        <SuccessContainer>
          <FormattedMessage id="newsletter.pending" />
        </SuccessContainer>
      )}
      {subscriptionResult === "ALREADY_REGISTERED" && (
        <AlreadyRegisteredContainer>
          <FormattedMessage id="newsletter.alreadyRegistered" />
        </AlreadyRegisteredContainer>
      )}
      {subscriptionResult === "CONTACT_SUPPORT" && (
        <AlreadyRegisteredContainer>
          <FormattedMessage id="newsletter.contactSupport" />
        </AlreadyRegisteredContainer>
      )}
      {subscriptionResult === "ERROR" && (
        <ErrorContainer>
          <FormattedMessage id="newsletter.error" />
        </ErrorContainer>
      )}
    </Container>
  );
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      linkPrivacy {
        _allHandleLocales {
          locale
          value
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
  select {
    margin-bottom: 30px;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.error};
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-left: 20px;
    margin-right: 20px;
  }
  div:first-child {
    margin-left: 0;
  }
  div:last-child {
    margin-right: 0;
  }
`;

const DateBlock = styled.div`
  flex: 1;
`;

const AlreadyRegisteredContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
`;

const SignUpButton = styled(Button)<{ disabled: boolean; role: string }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

const SignUpForm = styled.form`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Label = styled.label`
  font-family: soleil, sans-serif;
  font-size: 13px;
  letter-spacing: 0.08em;
  color: #333;
  padding-bottom: 8px;
`;

const Checkbox = styled.div<{ hasError: boolean }>`
  margin-bottom: 30px;
  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ theme, hasError }) =>
        hasError &&
        css`
          border: 2px solid ${theme.colors.error};
        `}
    }
    & > span {
      margin: 0;
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      span {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;
        &:hover {
          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`;

export default NewsletterSection;
