import StoreLocator from "components/StoreLocator";
import Wrapper from "components/Wrapper";
import React from "react";
import styled from "styled-components";
import { StoreLocatorRecord } from "templates/page";

interface Props {
  content: StoreLocatorRecord;
}

const StoreLocatorSection: React.SFC<Props> = ({ content }) => {
  return (
    <Container>
      <Wrapper>
        <StoreLocator stores={content.stores} />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  position: relative;
`;

export default StoreLocatorSection;
