import Banner from "components/sections/Banner";
import FormInfluencer from "components/sections/FormInfluencer";
import Gallery from "components/sections/Gallery";
import Hero from "components/sections/Hero";
import Image from "components/sections/Image";
import ImageLink from "components/sections/ImageLink";
import ImageText from "components/sections/ImageText";
import Link from "components/sections/Link";
import MarkupFromLink from "components/sections/MarkupFromLink";
import NewsletterFormOnlineOffline from "components/sections/NewsletterFormOnlineOffline";
import NewsSlider from "components/sections/NewsSlider";
import PressSlider from "components/sections/PressSlider";
import ProductSection from "components/sections/Product";
import ProductSlider from "components/sections/ProductSlider";
import Rte from "components/sections/Rte";
import ScriptComponent from "components/sections/ScriptComponent";
import ShareYourMessage from "components/sections/ShareYourMessage";
import StoreLocatorSection from "components/sections/StoreLocator";
import Video from "components/sections/Video";
import ZohoForm from "components/sections/ZohoForm";
import SEO from "components/SEO";
import { Store } from "components/StoreLocator";
import { graphql } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { DatoCmsProduct, ShopifyProduct } from "graphql-typings";
import React from "react";
import { getLocalizedField } from "utils/common/locale";
import NewsletterSection from "../components/sections/NewsletterSection";
import { universalBtoa } from "utils/product";

interface GatsbyGalleryImage {
  alt?: string;
  gatsbyImageData: IGatsbyImageData;
}

interface HeroSlide {
  title: string;
  subtitle: string;
  link: any;
  linkLabel: string;
  image: GatsbyGalleryImage & { url: string };
  imageMobile: GatsbyGalleryImage & { url: string };
  imageColor: "light" | "dark";
}

export interface ProductSliderRecord {
  __typename: "DatoCmsProductSlider";
  title: string;
  products: DatoCmsProduct[];
  link: any;
  linkLabel: string;
}

export interface HeroRecord {
  __typename: "DatoCmsHero";
  kind: "image" | "gallery";
  slides: HeroSlide[];
}

export interface PressSliderRecord {
  __typename: "DatoCmsPressSlider";
  title: string;
  images: GatsbyGalleryImage[];
}

export interface ImageLinkRecord {
  __typename: "DatoCmsImageLink";
  title: string;
  subtitle: string;
  link?: any;
  customLink?: string;
  linkLabel: string;
  images: GatsbyGalleryImage[];
  imageAlignment: "left" | "right";
}

export interface ImageTextRecord {
  __typename: "DatoCmsImageText";
  textNode: { childMarkdownRemark: { html: string } };
  images: GatsbyGalleryImage[];
  imageAlignment: "left" | "right";
}

export interface RteRecord {
  __typename: "DatoCmsRte";
  textNode: { childMarkdownRemark: { html: string } };
  textAlignment: "left" | "center" | "right";
}

export interface ImageRecord {
  __typename: "DatoCmsImage";
  images: GatsbyGalleryImage[];
  backgroundAlignment: "left" | "right";
}

export interface VideoRecord {
  __typename: "DatoCmsVideo";
  video: { url: string };
  fallbackImage: GatsbyGalleryImage;
  link: any;
  linkLabel: string;
}

export interface NewsletterRecord {
  __typename: "DatoCmsNewsletterForm";
  listId: string;
  title: string;
  textNode: { childMarkdownRemark: { html: string } };
  listFields: string;
  listRequiredFields: string;
}

export interface GalleryRecord {
  __typename: "DatoCmsGallery";
  images: GatsbyGalleryImage[];
}

export interface LinkRecord {
  __typename: "DatoCmsLink";
  link: any;
  linkLabel: string;
  alignment: "left" | "center" | "right";
}

export interface StoreLocatorRecord {
  __typename: "DatoCmsStoreLocator";
  stores: Store[];
}

export interface NewsletterFormOnlineOfflineRecord {
  __typename: "DatoCmsNewsletterFormOnlineOffline";
  title: string;
  textNode: { childMarkdownRemark: { html: string } };
  privacyTextNode: { childMarkdownRemark: { html: string } };
  onlineListId: string;
  offlineListId: string;
  stores: Store[];
}

export interface FormInfluencerRecord {
  __typename: "DatoCmsFormInfluencer";
  title: string;
  textNode: { childMarkdownRemark: { html: string } };
  privacyTextNode: { childMarkdownRemark: { html: string } };
  listId: string;
}

export interface ShareYourMessageRecord {
  __typename: "DatoCmsShareYourMessage";
  messages: {
    text: string;
    name: string;
    age: number;
  }[];
}

export interface NewsSliderRecord {
  __typename: "DatoCmsNewsSlider";
  title: string;
}

export interface DatoCmsScript {
  __typename: "DatoCmsScript";
  scriptParameters: any;
}

export interface DatoCmsProductSection {
  __typename: "DatoCmsProductSection";
  product: any;
}

export interface DatoCmsBanner {
  __typename: "DatoCmsBanner";
  title: string;
  textNode: { childMarkdownRemark: { html: string } };
  backgroundImage?: {
    url: string;
    alt?: string;
  };
  backgroundImageMobile?: {
    url: string;
    alt?: string;
  };
  link: any;
  linkLabel: string;
  marginTop: string;
  marginBottom: string;
}

export interface DatoCmsZohoForm {
  __typename: "DatoCmsZohoForm";
  title: string;
  textNode: { childMarkdownRemark: { html: string } };
  formId: string;
  frameHeight: number;
}

export interface DatoCmsMarkupFromLink {
  __typename: "DatoCmsMarkupFromLink";
  linkToMarkup: string;
}

type ModularContent =
  | ProductSliderRecord
  | HeroRecord
  | ImageLinkRecord
  | RteRecord
  | ImageRecord
  | VideoRecord
  | ImageTextRecord
  | PressSliderRecord
  | NewsletterRecord
  | GalleryRecord
  | LinkRecord
  | StoreLocatorRecord
  | NewsletterFormOnlineOfflineRecord
  | FormInfluencerRecord
  | ShareYourMessageRecord
  | NewsSliderRecord
  | DatoCmsScript
  | DatoCmsProductSection
  | DatoCmsBanner
  | DatoCmsZohoForm
  | DatoCmsMarkupFromLink;

interface Props {
  data: {
    datoCmsPage: {
      seo: any;
      title: string;
      handle: string;
      _allHandleLocales: { locale: string; value: string }[];
      _allBodyLocales: { locale: string; value: any[] }[];
      noindex: boolean;
    };
    allShopifyProduct: { nodes: ShopifyProduct[] };
    datoCmsGeneral: {
      locale: string;
      additionalProductDetailsNode: { childMarkdownRemark: { html: string } };
      logo: { url: string };
    };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: any[];
    pagePath: string;
  };
}

/**
 * Generic page template
 */
const PageTemplate = ({ data, pageContext }: Props) => {
  const { locale, pagePath } = pageContext;
  const {
    datoCmsPage: page,
    allShopifyProduct: { nodes: shopifyProducts },
    datoCmsGeneral,
  } = data;

  const {
    logo: { url: logoUrl },
  } = datoCmsGeneral;

  const seo = page.seo;

  /**
   * Render modular content in page
   */
  const renderSection = (content: ModularContent, index: number) => {
    switch (content.__typename) {
      case "DatoCmsProductSlider":
        return (
          <ProductSlider
            key={index}
            content={content}
            shopifyProducts={shopifyProducts}
          />
        );
      case "DatoCmsHero":
        return <Hero key={index} content={content} />;
      case "DatoCmsImage":
        return <Image key={index} content={content} />;
      case "DatoCmsPressSlider":
        return <PressSlider key={index} content={content} />;
      case "DatoCmsImageText":
        return <ImageText key={index} content={content} />;
      case "DatoCmsRte":
        return <Rte key={index} content={content} />;
      case "DatoCmsImageLink":
        return <ImageLink key={index} content={content} />;
      case "DatoCmsNewsletterForm":
        return <NewsletterSection key={index} content={content} />;
      case "DatoCmsVideo":
        return <Video key={index} content={content} />;
      case "DatoCmsGallery":
        return <Gallery key={index} content={content} />;
      case "DatoCmsLink":
        return <Link key={index} content={content} />;
      case "DatoCmsStoreLocator":
        return <StoreLocatorSection key={index} content={content} />;
      case "DatoCmsNewsletterFormOnlineOffline":
        return <NewsletterFormOnlineOffline key={index} content={content} />;
      case "DatoCmsFormInfluencer":
        return <FormInfluencer key={index} content={content} />;
      case "DatoCmsShareYourMessage":
        return <ShareYourMessage key={index} content={content} />;
      case "DatoCmsNewsSlider":
        return <NewsSlider key={index} content={content} />;
      case "DatoCmsScript":
        return <ScriptComponent key={index} data={content} />;
      case "DatoCmsProductSection":
        const shopifyProduct = shopifyProducts.find(
          (p) => universalBtoa(p.storefrontId) === content.product.shopifyId
        );
        return (
          <ProductSection
            key={index}
            datoCmsGeneral={datoCmsGeneral}
            datoCmsProduct={content.product}
            shopifyProduct={shopifyProduct}
          />
        );
      case "DatoCmsBanner":
        return <Banner key={index} content={content} />;
      case "DatoCmsZohoForm":
        return <ZohoForm key={index} content={content} />;
      case "DatoCmsMarkupFromLink":
        return <MarkupFromLink key={index} content={content} />;
      default:
        return null;
    }
  };

  return (
    <>
      <SEO
        title={(seo && seo.title) || page.title}
        titleSuffix={seo && seo.title ? "" : undefined}
        description={seo && seo.description}
        meta={[
          {
            name: "google-site-verification",
            content: "DsKxqOcANkrPBZt-qtr1fYdk_WF8k2csQJeP8Acmx6A",
          },
          ...(page.noindex ? [{ name: "robots", content: "noindex" }] : []),
        ]}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      >
        {!page.handle && (
          <script type="application/ld+json">
            {`{
              "@context" : "http://schema.org",
              "@type" : "Organization",
              "address": [{
                "@type": "PostalAddress",
                "addressLocality": "La Spezia, SP, Italy",
                "postalCode": "19100",
                "streetAddress": "Via dei Mille, 99"
              },
              {
                "@type": "PostalAddress",
                "addressLocality": "Porcari, LU, Italy",
                "postalCode": "55016",
                "streetAddress": "Via Leccio, 21"
              }],
              "legalName" : "Rue des Mille",
              "name" : "Rue des Mille",
              "telephone": "+39 (348) 7261540",
              "url" : "https://www.ruedesmille.com/",
              "logo" : "${logoUrl}",
              "sameAs" : [
                "https://www.facebook.com/RuedesMilleItalia",
                "https://www.instagram.com/ruedesmille/",
                "https://www.linkedin.com/company/rue-des-mille/",
                "https://twitter.com/ruedesmille"
              ]
            }`}
          </script>
        )}
      </SEO>
      {getLocalizedField(page._allBodyLocales, locale)!.value.map(
        (section, index) => {
          return renderSection(section, index);
        }
      )}
    </>
  );
};

export const query = graphql`
  query pageQuery(
    $pageId: String
    $featuredProductIds: [String]
    $locale: String
  ) {
    datoCmsPage(id: { eq: $pageId }) {
      title
      handle
      _allHandleLocales {
        locale
        value
      }
      _allBodyLocales {
        locale
        value {
          __typename
          ... on DatoCmsHero {
            slides {
              title
              subtitle
              image {
                alt
                gatsbyImageData(layout: CONSTRAINED)
                url
              }
              imageMobile {
                alt
                gatsbyImageData(layout: CONSTRAINED)
                url
              }
              imageColor
              link {
                __typename
                ... on DatoCmsPage {
                  handle
                }
                ... on DatoCmsCategory {
                  handle
                }
                ... on DatoCmsProduct {
                  handle
                  category {
                    handle
                  }
                }
                ... on DatoCmsCollection {
                  handle
                }
                ... on DatoCmsNews {
                  handle
                }
              }
              linkLabel
            }
          }
          ... on DatoCmsImageLink {
            title
            subtitle
            link {
              __typename
              ... on DatoCmsProduct {
                handle
                category {
                  handle
                }
              }
              ... on DatoCmsCategory {
                handle
              }
              ... on DatoCmsPage {
                handle
              }
              ... on DatoCmsCollection {
                handle
              }
            }
            customLink
            linkLabel
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            imageAlignment
          }
          ... on DatoCmsImageText {
            textNode {
              childMarkdownRemark {
                html
              }
            }
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            imageAlignment
          }
          ... on DatoCmsRte {
            textNode {
              childMarkdownRemark {
                html
              }
            }
            textAlignment
          }
          ... on DatoCmsImage {
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            backgroundAlignment
          }
          ... on DatoCmsProductSlider {
            title
            products {
              shopifyId
              title
              handle
              featured
              category {
                handle
              }
            }
            link {
              __typename
              ... on DatoCmsCategory {
                handle
              }
              ... on DatoCmsCollection {
                handle
              }
            }
            linkLabel
          }
          ... on DatoCmsPressSlider {
            title
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
            }
          }
          ... on DatoCmsNewsletterForm {
            listId
            title
            textNode {
              childMarkdownRemark {
                html
              }
            }
            listFields
            listRequiredFields
          }
          ... on DatoCmsVideo {
            video {
              url
            }
            fallbackImage {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            linkLabel
            link {
              __typename
              ... on DatoCmsProduct {
                handle
                category {
                  handle
                }
              }
              ... on DatoCmsCategory {
                handle
              }
              ... on DatoCmsCollection {
                handle
              }
            }
          }
          ... on DatoCmsGallery {
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          ... on DatoCmsLink {
            link {
              __typename
              ... on DatoCmsPage {
                handle
              }
              ... on DatoCmsCategory {
                handle
              }
              ... on DatoCmsProduct {
                handle
                category {
                  handle
                }
              }
              ... on DatoCmsCollection {
                handle
              }
            }
            linkLabel
            alignment
          }
          ... on DatoCmsStoreLocator {
            stores {
              id
              name
              address
              zipCode
              city
              country
              coordinates {
                latitude
                longitude
              }
              featured
            }
          }
          ... on DatoCmsNewsletterFormOnlineOffline {
            title
            textNode {
              childMarkdownRemark {
                html
              }
            }
            privacyTextNode {
              childMarkdownRemark {
                html
              }
            }
            onlineListId
            offlineListId
            stores {
              id
              name
              address
              zipCode
              city
              country
              coordinates {
                latitude
                longitude
              }
              featured
            }
          }
          ... on DatoCmsFormInfluencer {
            title
            textNode {
              childMarkdownRemark {
                html
              }
            }
            privacyTextNode {
              childMarkdownRemark {
                html
              }
            }
            listId
          }
          ... on DatoCmsShareYourMessage {
            messages {
              text
              name
              age
            }
          }
          ... on DatoCmsScript {
            scriptParameters
          }
          ... on DatoCmsNewsSlider {
            title
          }

          ... on DatoCmsProductSection {
            product {
              shopifyId
              title
              handle
              description
              note
              noteTextNode {
                childMarkdownRemark {
                  html
                }
              }
              descriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              category {
                shopifyHandle
                title
                _allHandleLocales {
                  locale
                  value
                }
              }
              relatedProducts {
                shopifyId
              }
              variants {
                shopifyId
              }
              _allHandleLocales {
                locale
                value
              }
              _allTitleLocales {
                locale
                value
              }
              seo {
                title
                description
                twitterCard
                image {
                  url
                }
              }
              productCustomisation
              productCustomisationCase
              productCustomisationSymbols
              productCustomisationNumberOfFields
              productCustomisationCharactersLimit
              productCustomisationProgressivePrice
              additionalCta
              additionalCtaTitle
              additionalCtaLink {
                __typename
                ... on DatoCmsProduct {
                  handle
                  category {
                    handle
                  }
                }
                ... on DatoCmsCategory {
                  handle
                }
                ... on DatoCmsPage {
                  handle
                }
              }
              additionalCtaCustomLink
            }
          }
          ... on DatoCmsBanner {
            id
            title
            textNode {
              childMarkdownRemark {
                html
              }
            }
            backgroundImage {
              url
            }
            backgroundImageMobile {
              url
            }
            linkLabel
            marginTop
            marginBottom
            link {
              __typename
              ... on DatoCmsPage {
                handle
              }
            }
          }
          ... on DatoCmsZohoForm {
            title
            textNode {
              childMarkdownRemark {
                html
              }
            }
            formId
            frameHeight
          }
          ... on DatoCmsMarkupFromLink {
            linkToMarkup
          }
        }
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      noindex
    }
    allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $featuredProductIds } }
    ) {
      nodes {
        storefrontId
        handle
        description
        title
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        variants {
          availableForSale
          storefrontId
          title
          compareAtPrice
          price
          media {
            id
            shopifyId
            mediaContentType
            ... on ShopifyMediaImage {
              image {
                originalSrc
                altText
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
    datoCmsGeneral(locale: { eq: $locale }) {
      locale
      additionalProductDetailsNode {
        childMarkdownRemark {
          html
        }
      }
      logo {
        url
      }
    }
  }
`;

export default PageTemplate;
