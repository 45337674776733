import ButtonLink from "components/ButtonLink";
import Wrapper from "components/Wrapper";
import { useLocale } from "hooks/useLocale";
import React from "react";
import { LinkRecord } from "templates/page";
import { computePathFromLocalizedData } from "utils/common/navigation";
import styled from "styled-components";

interface Props {
  content: LinkRecord;
  alignment?: "left" | "center" | "right";
}

const Link: React.SFC<Props> = ({ content }) => {
  const { link, linkLabel, alignment } = content;
  const { locale, defaultLocale } = useLocale();
  return (
    <Wrapper>
      <Container alignment={alignment}>
        <LinkContainer>
          <ButtonLink
            size="large"
            to={computePathFromLocalizedData(
              locale,
              defaultLocale,
              link.__typename,
              link.handle,
              link.category && link.category.handle
            )}
          >
            {linkLabel}
          </ButtonLink>
        </LinkContainer>
      </Container>
    </Wrapper>
  );
};

export default Link;

const Container = styled.div`
  width: 100%;
  margin: 60px 0;
  text-align: ${(props: Props) =>
    ({
      left: "left",
      center: "center",
      right: "right"
    }[props.alignment || "left"])};
`;

const LinkContainer = styled.div`
  width: 320px;
  display: inline-blocK;
  @media (max-width: 400px) {
    width: 90%;
  }
`;
