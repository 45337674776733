import Wrapper from "components/Wrapper";
import React from "react";
import styled from "styled-components";
import { DatoCmsMarkupFromLink } from "templates/page";

interface Props {
  content: DatoCmsMarkupFromLink;
}

const MarkupFromLink: React.SFC<Props> = ({ content }) => {
  const [markup, setMarkup] =
    React.useState<{ success: boolean; content?: any }>();

  React.useEffect(() => {
    const fetchMarkup = async () => {
      const response = await fetch(content.linkToMarkup);
      const parsedResponse = await response.json();
      setMarkup(parsedResponse);
    };
    fetchMarkup();
  }, [content]);

  if (markup?.success) {
    return (
      <Wrapper>
        <Content dangerouslySetInnerHTML={{ __html: markup.content }} />
      </Wrapper>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
  select {
    margin-bottom: 30px;
  }
`;

const Content = styled.div`
  color: #333;
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.8em;
    margin: 0;
    margin-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 20px;

    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.04em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
  }
  pre {
    white-space: normal;
  }
  img {
    width: 100%;
    display: block;
    margin: 20px 0;
  }
  ul,
  ol {
    li {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.8em;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  blockquote {
    background-color: ${({ theme }) => theme.colors.background2};
    padding: 60px 40px;

    margin: 30px auto;
    box-sizing: border-box;
    position: relative;

    width: 100%;
    &:before,
    &:after {
      content: "“";
      display: block;
      position: absolute;
      color: ${({ theme }) => theme.colors.main};
      opacity: 0.3;
      font-size: 90px;
      font-weight: 400;
    }
    &:before {
      content: "“";
      top: -10px;
      left: 10px;
    }
    &:after {
      content: "”";
      bottom: -50px;
      right: 10px;
    }
    p {
      width: 100%;
      position: relative;
      color: ${({ theme }) => theme.colors.main};
      font-weight: 700;
      padding: 0;
    }
    ul {
      list-style: none;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      text-align: right;
      li {
        color: ${({ theme }) => theme.colors.main};
        font-size: 14px;
        margin-bottom: -20px;
      }
    }
  }
`;

export default MarkupFromLink;
