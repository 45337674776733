import Wrapper from "components/Wrapper";
import { useLocale } from "hooks/useLocale";
import React, { useState, ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import { ShareYourMessageRecord } from "templates/page";
import InputText from "components/InputText";
import { useTranslation } from "hooks/useTranslation";
import Select from "components/Select";
import Button from "components/Button";
import InputTextArea from "components/InputTextArea";
import { FormattedMessage } from "react-intl";

interface Props {
  content: ShareYourMessageRecord;
}

const ShareYourMessage: React.SFC<Props> = ({ content }) => {
  const { formatMessage } = useTranslation();
  const [text, setText] = useState<string>();
  const [name, setName] = useState<string>();
  const [age, setAge] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);
  const [submissionStatus, setSubmissionStatus] = useState<
    "sent" | "error" | undefined
  >();

  return (
    <Wrapper>
      <Container>
        <InputTextArea
          as="textarea"
          placeholder={formatMessage({
            id: "shareYourMessage.secret",
            defaultMessage: "Secret"
          })}
          value={text || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setText(e.target.value)
          }
        />
        <NameAgeWrapper>
          <InputText
            type="text"
            placeholder={formatMessage({
              id: "label.name",
              defaultMessage: "Name"
            })}
            value={name || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
          <InputText
            type="number"
            placeholder={formatMessage({
              id: "label.age",
              defaultMessage: "Age"
            })}
            value={age || ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAge(Number(e.target.value))
            }
          />
        </NameAgeWrapper>
        {!submissionStatus && (
          <ButtonContainer>
            <Button
              size="normal"
              disabled={!text || !name || !age}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                const response = await fetch(
                  "/.netlify/functions/user-message",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                      text,
                      name,
                      age
                    })
                  }
                );
                if (response.status === 200) {
                  setSubmissionStatus("sent");
                } else {
                  setSubmissionStatus("error");
                }
                setLoading(false);
              }}
            >
              <FormattedMessage id="shareYourMessage.send" />
            </Button>
          </ButtonContainer>
        )}
        {submissionStatus === "sent" && (
          <SubmissionMessage>
            <FormattedMessage id="shareYourMessage.success" />
          </SubmissionMessage>
        )}
        {submissionStatus === "error" && (
          <SubmissionMessage>
            <FormattedMessage id="shareYourMessage.error" />
          </SubmissionMessage>
        )}
        <List>
          {content.messages.map(message => (
            <MessageContainer>
              <MessageBody>{message.text}</MessageBody>
              <MessageDetails>
                {message.name} - {message.age}
              </MessageDetails>
            </MessageContainer>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 800px;
  margin-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 900px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameAgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${InputText}:first-child {
    margin-right: 5px;
  }
  ${InputText}:last-child {
    margin-left: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubmissionMessage = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  color: ${({ theme }) => theme.colors.main};
  font-size: 18px;
`;

const MessageContainer = styled.div`
  margin-top: 80px;
`;

const MessageBody = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
`;

const MessageDetails = styled.div`
  color: ${({ theme }) => theme.colors.main};
  text-align: right;
`;

export default ShareYourMessage;
