import Wrapper from "components/Wrapper";
import { useProductConfigurator } from "hooks/useProductConfigurator";
import { kebabCase } from "lodash";
import React from "react";
import styled from "styled-components";
import Gallery from "components/Gallery";
import Info from "components/Info";
import { mapMediaToImages } from "utils/image";

interface ProductProps {
  datoCmsGeneral: any;
  datoCmsProduct: any;
  shopifyProduct: any;
}

const ProductSection: React.SFC<ProductProps> = (props) => {
  const { datoCmsGeneral, datoCmsProduct, shopifyProduct } = props;

  const {
    selectedVariant,
    selectedOptions,
    customAttributes,
    extractOptionNamesOrdered,
    extractLocalizedOptions,
    reconcileVariantSelector,
    reconcileCustomText,
    reconcileEngraving,
  } = useProductConfigurator(shopifyProduct, datoCmsProduct);

  const images = mapMediaToImages(shopifyProduct.media)?.filter(
    (image: any) =>
      !image.altText || !kebabCase(image.altText).includes("carousel")
  );

  return (
    <Container>
      <Wrapper>
        <ProductContainer>
          <GalleryContainer>
            <Gallery
              datocmsProduct={datoCmsProduct}
              images={images}
              selectedVariant={selectedVariant}
            />
          </GalleryContainer>
          <InfoContainer>
            <Info
              datocmsProduct={datoCmsProduct}
              shopifyProduct={shopifyProduct}
              otherSubjects={[]}
              general={datoCmsGeneral}
              selectedVariant={selectedVariant}
              selectedOptions={selectedOptions}
              customAttributes={customAttributes!}
              extractOptionNamesOrdered={extractOptionNamesOrdered}
              extractLocalizedOptions={extractLocalizedOptions}
              reconcileVariantSelector={reconcileVariantSelector}
              reconcileCustomText={reconcileCustomText}
              reconcileEngraving={reconcileEngraving}
            />
          </InfoContainer>
        </ProductContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 40px 0;
`;

const ProductContainer = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const GalleryContainer = styled.div`
  width: 500px;
  padding-bottom: 40px;
  flex: 0 0 500px;
  @media (max-width: 1100px) {
    width: 480px;
    flex: 0 0 480px;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 40px;
    padding-bottom: 40px;
    flex: 0 0 auto;
  }
  @media (max-width: 680px) {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding: 0;
    padding-bottom: 20px;
  }
`;

const InfoContainer = styled.div`
  padding-left: 120px;
  padding-right: 80px;
  width: 100%;
  @media (max-width: 1200px) {
    padding-right: 40px;
    padding-left: 80px;
  }
  @media (max-width: 1100px) {
    padding-left: 40px;
    padding-right: 20px;
  }
  @media (max-width: 900px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 680px) {
    padding: 0 20px;
  }
  @media (max-width: 450px) {
    padding: 0;
  }
`;

export default ProductSection;
