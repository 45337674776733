import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import Wrapper from "components/Wrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import useComponentSize from "hooks/useComponentSize";
import React, { useRef } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
  DotGroup,
} from "react-carousel";
import styled from "styled-components";
import { GalleryRecord } from "templates/page";

interface Props {
  content: GalleryRecord;
}

const Gallery: React.SFC<Props> = ({ content }) => {
  const { images } = content;

  // Refs
  const ref = useRef(null);
  const size = useComponentSize(ref);

  const visibleSlides = size.width < 600 ? 1 : size.width < 900 ? 2 : 3;

  return (
    <Background>
      <Container>
        <Wrapper>
          <GalleryContainer ref={ref}>
            <CarouselProvider
              totalSlides={images.length}
              visibleSlides={visibleSlides}
            >
              <Slider>
                {images.map((image, index) => (
                  <Slide key={index} index={index}>
                    <ImageContainer>
                      <Image
                        image={image.gatsbyImageData}
                        alt={image.alt || ""}
                      />
                    </ImageContainer>
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
              <DotGroup />
            </CarouselProvider>
          </GalleryContainer>
        </Wrapper>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.background2};
`;

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;

  @media (max-width: 1280px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
`;

const GalleryContainer = styled.div`
  position: relative;
  button.carousel__back-button,
  button.carousel__next-button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
  button.carousel__back-button {
    left: -40px;
  }
  button.carousel__next-button {
    right: -40px;
  }
  .carousel__dot-group {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
    .carousel__dot {
      font-size: 0;
      line-height: 0;
      display: inline-block;
      padding: 8px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &::before {
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 3px;
        display: block;
        background-color: ${({ theme }) => theme.colors.main};
        opacity: 0.5;
        transition: 0.3s all;
      }
      &.carousel__dot--selected {
        &::before {
          opacity: 1;
        }
      }
    }
  }
`;

const ImageContainer = styled.div`
  padding: 20px;
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  display: block;
`;

export default Gallery;
