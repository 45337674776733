import Link from "components/ButtonLink";
import { GatsbyImage } from "gatsby-plugin-image";
import useComponentSize from "hooks/useComponentSize";
import { useLocale } from "hooks/useLocale";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { VideoRecord } from "templates/page";
import { computePathFromLocalizedData } from "utils/common/navigation";

interface Props {
  content: VideoRecord;
}

const Video: React.SFC<Props> = ({ content }) => {
  const { locale, defaultLocale } = useLocale();
  const {
    video: { url },
    fallbackImage,
    link,
    linkLabel,
  } = content;

  const [isMobile, setMobile] = useState(false);
  const videoContainerRef = useRef(null);
  const videoContainerSize = useComponentSize(videoContainerRef);

  // By now supporting only default youtube links
  let videoUrl = url;
  if (videoUrl.includes("youtube")) {
    const videoParts = videoUrl.split("?v=");
    const videoId = videoParts && videoParts.length > 0 && videoParts[1];
    videoUrl = videoUrl
      .replace("watch", "embed")
      .replace("?v=", "/")
      .concat(
        `?autoplay=1&controls=0&disablekb=1&fs=0&iv_load_policy=3&loop=1&modestbranding=1&mute=1&playlist=${
          videoId || ""
        }&rel=0&showinfo=0&version=3`
      );
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.navigator.userAgent &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const computeVideoWrapperStyle = () => {
    const ratio = 16 / 9;
    let style;
    const youtubeMargin = 120;

    if (
      videoContainerSize.width / (videoContainerSize.height + youtubeMargin) >=
      ratio
    ) {
      /* example 21:9 */
      style = {
        width: videoContainerSize.width,
        height: videoContainerSize.width / ratio,
        marginTop:
          -(videoContainerSize.width / ratio - videoContainerSize.height) / 2,
        marginLeft: 0,
      };
    } else {
      style = {
        width: (videoContainerSize.height + youtubeMargin) * ratio,
        height: videoContainerSize.height + youtubeMargin,
        marginTop: -(youtubeMargin / 2),
        marginLeft:
          -(
            (videoContainerSize.height + youtubeMargin) * ratio -
            videoContainerSize.width
          ) / 2,
      };
    }
    return {
      ...style,
    };
  };

  return (
    <Container>
      <ImageContainer mobile={isMobile}>
        <Image
          image={fallbackImage.gatsbyImageData}
          alt={fallbackImage.alt || ""}
        />
      </ImageContainer>

      <VideoContainer ref={videoContainerRef} mobile={isMobile}>
        <VideoWrapper style={computeVideoWrapperStyle()}>
          <IFramePlayerStyle src={videoUrl} frameBorder="0" />
        </VideoWrapper>
      </VideoContainer>
      <Overlay mobile={isMobile}>
        <VideoMobile mobile={isMobile}>
          <IFramePlayerStyle src={videoUrl} frameBorder="0" />
        </VideoMobile>
        {link && (
          <LinkContainer mobile={isMobile}>
            <Link
              size="large"
              to={computePathFromLocalizedData(
                locale,
                defaultLocale,
                link.__typename,
                link.handle,
                link.category && link.category.handle
              )}
            >
              {linkLabel}
            </Link>
          </LinkContainer>
        )}
      </Overlay>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  opacity: 1;
  transition: 1s all;
  height: auto;
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${({ mobile }: { mobile: boolean }) => (mobile ? `block` : `none`)};
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const VideoContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 85vh;
  width: 100%;
  overflow: hidden;
  display: ${({ mobile }: { mobile: boolean }) => (mobile ? `none` : `block`)};
`;

const VideoWrapper = styled.div`
  position: relative;
  height: calc(85vh + 200px);
  margin-top: -100px;
`;

const IFramePlayerStyle = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoMobile = styled.div`
  width: 100%;
  padding-top: 56.25%;
  box-shadow: 0px 0px 20px 0px rgba(151, 123, 43, 0.5);
  display: ${({ mobile }: { mobile: boolean }) => (mobile ? `block` : `none`)};
  position: relative;
`;

const Overlay = styled.div`
  position: ${({ mobile }: { mobile: boolean }) =>
    mobile ? `relative` : `absolute`};

  background-color: ${({ mobile }: { mobile: boolean }) =>
    mobile ? "rgba(0, 0, 10, 0)" : "rgba(0, 0, 10, 0.2)"};
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  padding: ${({ mobile }: { mobile: boolean }) => (mobile ? `20px` : `0px`)};
`;

const LinkContainer = styled.div`
  position: ${({ mobile }: { mobile: boolean }) =>
    mobile ? `relative` : `absolute`};
  width: 320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  bottom: ${({ mobile }: { mobile: boolean }) => (mobile ? `auto` : `100px`)};
  margin-top: 20px;
  @media (max-width: 400px) {
    width: 90%;
  }
`;

export default Video;
