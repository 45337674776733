import { LoadScript } from "@react-google-maps/api";
import Button from "components/Button";
import StarIcon from "components/icons/Star";
import InputText from "components/InputText";
import Spinner from "components/Spinner";
import { Coordinate, Store } from "components/StoreLocator";
import { useLocale } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import { uniqueId } from "lodash";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { NewsletterFormOnlineOfflineRecord } from "templates/page";
import {
  compareDistances,
  getDistanceFromLatLonInKm,
} from "utils/common/store-locator";

interface Props {
  content: NewsletterFormOnlineOfflineRecord;
}

const SubscriptionElement: React.SFC<Props> = ({ content }) => {
  const [newsletterPermission, setNewsletterPermission] = useState(false);
  const [newsletterPermissionError, setNewsletterPermissionError] =
    useState(false);
  const [onlineSelected, setOnlineSelected] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState("");
  const [googleApi, setGoogleApi] = useState();
  const [searchString, setSearchString] = useState("");
  const [selectedStore, setSelectedStore] = useState<Store>();
  const [isLoadingPosition, setLoadingPosition] = useState(false);
  const [distanceReference, setDistanceReference] = useState<Coordinate>();
  const [subscriptionResult, setSubscriptionResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { locale } = useLocale();
  const { formatMessage } = useTranslation();

  useEffect(() => {
    // @ts-ignore
    if (window && window.google) {
      // @ts-ignore
      setGoogleApi(window.google);
    }
  }, []);

  const geolocate = () => {
    if ("geolocation" in navigator) {
      setLoadingPosition(true);
      setSelectedStore(undefined);
      navigator.geolocation.getCurrentPosition((position) => {
        const center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setDistanceReference(center);
        setLoadingPosition(false);
      });
    }
  };

  const geocode = (e: MouseEvent) => {
    e.preventDefault();
    if (googleApi) {
      setLoadingPosition(true);
      setSelectedStore(undefined);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: searchString }, (result) => {
        if (result && result.length > 0) {
          const center = {
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng(),
          };
          setDistanceReference(center);
        }
        setLoadingPosition(false);
      });
    }
  };

  async function submitPreprocessing(e: React.FormEvent<EventTarget>) {
    e.preventDefault();
    if (newsletterPermission) {
      setNewsletterPermissionError(false);
      if (email && firstName && lastName && (onlineSelected || selectedStore)) {
        try {
          setIsSubmitting(true);
          let birthdayFormatted;
          if (birthday) {
            const birthdayDate = new Date(birthday);
            birthdayFormatted = `${birthdayDate.getFullYear()}/${(
              "0" +
              (birthdayDate.getMonth() + 1)
            ).slice(-2)}/${("0" + birthdayDate.getDate()).slice(-2)}`;
          }

          const body = onlineSelected
            ? {
                email,
                firstName,
                lastName,
                listId: content.onlineListId,
                shouldConfirm: false,
                birthday: birthdayFormatted,
              }
            : {
                email,
                firstName,
                lastName,
                store: selectedStore!.name,
                storeAddress: `${selectedStore!.name} - ${
                  selectedStore!.address
                }, ${selectedStore!.city}, ${selectedStore!.zipCode}, ${
                  selectedStore!.country
                }`,
                listId: content.offlineListId,
                shouldConfirm: false,
                birthday: birthdayFormatted,
              };
          const response = await fetch(
            "/.netlify/functions/newsletter-subscription",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
          const data = await response.json();
          if (!response.ok) {
            switch (data.reason) {
              case "ALREADY_REGISTERED":
                setSubscriptionResult("ALREADY_REGISTERED");
                break;
              default:
                setSubscriptionResult("ERROR");
                break;
            }
          } else {
            (window as any).dataLayer.push({
              event: "gaEvent",
              eventCategory: "Form Online/Offline",
              eventAction: onlineSelected ? "Form Online" : "Form Offline",
            });

            switch (data.msg) {
              case "SUBSCRIBED":
                setSubscriptionResult("SUBSCRIBED");
                break;
              case "PENDING":
                setSubscriptionResult("PENDING");
                break;
              default:
                setSubscriptionResult("SUCCESS");
                break;
            }
          }
        } catch (err) {
          setSubscriptionResult("ERROR");
        } finally {
          setIsSubmitting(false);
        }
      }
    } else {
      setNewsletterPermissionError(true);
    }
  }

  const checkboxId = useMemo(() => uniqueId(), []);

  return (
    <Container>
      <Title>{content.title}</Title>
      {content.textNode && (
        <Text
          dangerouslySetInnerHTML={{
            __html: content.textNode.childMarkdownRemark.html,
          }}
        />
      )}
      {!subscriptionResult && (
        <>
          {content.onlineListId && (
            <OnlineOfflineButtonContainer>
              <OnlineOfflineButton
                onClick={() => setOnlineSelected(false)}
                active={!onlineSelected}
              >
                <FormattedMessage id="label.inStore" />
              </OnlineOfflineButton>
              <OnlineOfflineButton
                onClick={() => setOnlineSelected(true)}
                active={onlineSelected}
              >
                <FormattedMessage id="label.online" />
              </OnlineOfflineButton>
            </OnlineOfflineButtonContainer>
          )}
          <SignUpForm onSubmit={submitPreprocessing}>
            <Label>
              <FormattedMessage id="label.firstName" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.firstName" })}
              type="text"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            <Label>
              <FormattedMessage id="label.lastName" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.lastName" })}
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            <Label>
              <FormattedMessage id="label.birthday" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.birthday" })}
              type="date"
              value={birthday}
              onChange={(e) => setBirthday(e.target.value)}
            />
            <Label>
              <span>Email</span>
            </Label>
            <Input
              placeholder="Email"
              type="email"
              siqatrib="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {!onlineSelected && (
              <Stores>
                <InputAddress>
                  <SearchTitle>
                    <FormattedMessage id="label.searchAndSelectStore" />:
                  </SearchTitle>
                  <Search>
                    <InputText
                      type="text"
                      placeholder={formatMessage({ id: "label.address" })}
                      value={searchString}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearchString(e.target.value)
                      }
                    />
                    {/*
                     // @ts-ignore */}
                    <Button
                      onClick={geocode}
                      disabled={!googleApi || searchString === ""}
                    >
                      <FormattedMessage id="label.search" />
                    </Button>
                  </Search>
                  <GeolocateText>
                    <FormattedMessage
                      id="label.geolocate"
                      values={{
                        geolocateLink: (
                          <Geolocate onClick={geolocate}>
                            <FormattedMessage id="label.geolocateLink" />
                          </Geolocate>
                        ),
                      }}
                    />
                  </GeolocateText>
                </InputAddress>
                {isLoadingPosition && (
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                )}
                {distanceReference && (
                  <ListItem>
                    {content.stores
                      .sort(
                        (
                          { coordinates: c1 }: Store,
                          { coordinates: c2 }: Store
                        ) =>
                          compareDistances(
                            c1.latitude,
                            c1.longitude,
                            c2.latitude,
                            c2.longitude,
                            distanceReference.lat,
                            distanceReference.lng
                          )
                      )
                      .slice(0, 4)
                      .map((store) => {
                        const distance = getDistanceFromLatLonInKm(
                          store.coordinates.latitude,
                          store.coordinates.longitude,
                          distanceReference.lat,
                          distanceReference.lng
                        );
                        return (
                          <Item
                            key={`${store.id} - ${store.name}`}
                            selected={
                              (selectedStore &&
                                store.id === selectedStore.id) ||
                              false
                            }
                            onClick={() => setSelectedStore(store)}
                          >
                            {store.featured && (
                              <FeaturedLogo>
                                <StarIcon />
                              </FeaturedLogo>
                            )}
                            <Name>{store.name}</Name>
                            <Address>{store.address}</Address>
                            <City>
                              <CityName>{store.city}</CityName>
                              {store.zipCode && (
                                <ZipCode>, {store.zipCode}</ZipCode>
                              )}
                              {store.country && (
                                <Country>, {store.country}</Country>
                              )}
                            </City>
                            <Directions>
                              {distance && distance > 0 && (
                                <Distance>{distance.toFixed(2)} Km</Distance>
                              )}
                            </Directions>
                          </Item>
                        );
                      })}
                  </ListItem>
                )}
              </Stores>
            )}
            <SignUpButton
              disabled={
                !firstName ||
                !lastName ||
                !email ||
                (!onlineSelected && !selectedStore)
              }
              role="submit"
            >
              {!isSubmitting ? (
                <FormattedMessage id="label.join" />
              ) : (
                <Spinner />
              )}
            </SignUpButton>
          </SignUpForm>
          <Checkbox hasError={newsletterPermissionError}>
            <input
              id={checkboxId}
              type="checkbox"
              checked={newsletterPermission}
              onChange={() => {
                setNewsletterPermission((oldState) => !oldState);
                setNewsletterPermissionError(false);
              }}
            />
            <label
              htmlFor={checkboxId}
              dangerouslySetInnerHTML={{
                __html: content.privacyTextNode.childMarkdownRemark.html,
              }}
            />
          </Checkbox>
          <LoadScript
            googleMapsApiKey="AIzaSyDMgrlomo6vXd-L1KCV_WMX1u4L86R63Mo"
            language={locale}
            loadingElement={<></>}
            onLoad={() =>
              // @ts-ignore
              window && window.google && setGoogleApi(window.google)
            }
          />
        </>
      )}

      {subscriptionResult === "SUBSCRIBED" && (
        <SuccessContainer>
          <FormattedMessage id="newsletterFormOnlineOffline.success" />
        </SuccessContainer>
      )}
      {subscriptionResult === "PENDING" && (
        <SuccessContainer>
          <FormattedMessage id="newsletter.pending" />
        </SuccessContainer>
      )}
      {subscriptionResult === "ALREADY_REGISTERED" && (
        <AlreadyRegisteredContainer>
          <FormattedMessage id="newsletter.alreadyRegistered" />
        </AlreadyRegisteredContainer>
      )}
      {subscriptionResult === "ERROR" && (
        <ErrorContainer>
          <FormattedMessage id="newsletter.error" />
        </ErrorContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

const OnlineOfflineButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
`;

const OnlineOfflineButton = styled(Button)<{
  onClick: () => void;
  active: boolean;
}>`
  max-width: 100%;
  flex: 1;
  line-height: 1.2em;
  &:first-child {
    margin-right: 10px;
  }
  background-color: ${({ active, theme }) =>
    active ? theme.colors.main : "#a3acaf"};
`;

const Stores = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0;
    height: auto;
  }
`;

const SearchTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #333;
  font-weight: 600;
  padding-bottom: 15px;
`;

const InputAddress = styled.div`
  padding-bottom: 30px;
  text-align: center;

  @media (max-width: 750px) {
    width: 100%;
    text-align: left;
  }
`;

const Search = styled.div`
  display: flex;
  ${InputText} {
    font-size: 14px;
    margin-bottom: 15px;
  }
  ${Button} {
    width: 160px;
  }
  @media (max-width: 500px) {
    flex-direction: column;

    ${Button} {
      width: 100%;
      margin-bottom: 10px;
      max-width: 100%;
    }
  }
`;

const GeolocateText = styled.div`
  font-size: 14px;
  letter-spacing: 0.06em;
`;

const Geolocate = styled.span`
  color: ${({ theme }) => theme.colors.main};
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SpinnerContainer = styled.div`
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
`;

const ListItem = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: calc(100% + 10px);
  margin-left: -5px;
  overflow: auto;
`;

const Item = styled.li<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px;
  background-color: ${({ theme }) => theme.colors.background2};
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 1.8em;
  margin: 5px;
  width: calc(50% - 10px);
  border: ${({ selected, theme }) =>
    selected
      ? `2px solid ${theme.colors.main}`
      : `2px solid ${theme.colors.background2}`};
  transition: 0.3s all;
  position: relative;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 750px) {
    margin: 5px;
    width: calc(100% - 10px);
  }
`;

const FeaturedLogo = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 15px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Name = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 600;
  padding-bottom: 5px;
  line-height: 1.4em;
`;

const Address = styled.div`
  margin-bottom: -4px;
`;

const City = styled.div`
  display: flex;
`;

const Directions = styled.div``;

const CityName = styled.div``;

const ZipCode = styled.div``;

const Country = styled.div``;

const Distance = styled.div`
  color: ${({ theme }) => theme.colors.main};
  display: inline-block;
  margin-right: 10px;
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.error};
`;

const AlreadyRegisteredContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
`;

const SignUpButton = styled(Button)<{ disabled: boolean; role: string }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

const SignUpForm = styled.form`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Input = styled((props) => <input {...props} />)`
  background-color: #f7f7f7;
  border: 1px solid #c6c6c6;
  flex-grow: 2;
  padding: 10px 20px;
  font-family: soleil, sans-serif;
  font-size: 13px;
  letter-spacing: 0.08em;
  color: #333;
  font-weight: 400;
  height: 50px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-family: soleil, sans-serif;
  font-size: 13px;
  letter-spacing: 0.08em;
  color: #333;
  padding-bottom: 8px;
`;

const Checkbox = styled.div`
  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ hasError }: { hasError: boolean }) =>
        hasError &&
        css`
          border: 2px solid ${({ theme }) => theme.colors.error};
        `}
    }
    p {
      margin: 0;
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      span {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;
        &:hover {
          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`;

export default SubscriptionElement;
