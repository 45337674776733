import Button from "components/Button";
import Select from "components/Select";
import Spinner from "components/Spinner";
import { useTranslation } from "hooks/useTranslation";
import { uniqueId } from "lodash";
import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import { FormInfluencerRecord } from "templates/page";
import theme from "theme";
import countries from "utils/data/country.json";

interface Props {
  content: FormInfluencerRecord;
}

interface Province {
  code: string;
  name: string;
}

const FormInfluencer: React.SFC<Props> = ({ content }) => {
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [formState, setFormState] = useState<{
    firstName: string;
    lastName: string;
    birthday: {
      day: number;
      month: number;
      year: number;
    };
    country: string;
    city: string;
    zipCode: string;
    province: string;
    address: string;
    instagram: string;
    numberOfInstagramFollowers: string;
    email: string;
    phone: string;
    blog: string;
    website: string;
    youtube: string;
    tiktok: string;
  }>({
    firstName: "",
    lastName: "",
    birthday: { day: 1, month: 1, year: 1990 },
    country: "",
    city: "",
    zipCode: "",
    province: "",
    address: "",
    instagram: "",
    numberOfInstagramFollowers: "5.000 - 25.000",
    email: "",
    phone: "",
    blog: "",
    website: "",
    youtube: "",
    tiktok: "",
  });
  const [subscriptionResult, setSubscriptionResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useTranslation();

  const _extractProvinces = (country: string): Province[] => {
    const foundCountry = countries.find(
      (c) => c.code === country || c.name === country
    );
    return foundCountry ? foundCountry.provinces : [];
  };

  const provinces = useMemo(
    () => (formState.country ? _extractProvinces(formState.country) : []),
    [formState.country]
  );

  async function submitPreprocessing(e: React.FormEvent<EventTarget>) {
    e.preventDefault();
    const {
      firstName,
      lastName,
      birthday,
      country,
      city,
      zipCode,
      province,
      address,
      instagram,
      numberOfInstagramFollowers,
      email,
      phone,
      blog,
      website,
      youtube,
      tiktok,
    } = formState;
    if (privacy) {
      setPrivacyError(false);
      if (
        firstName &&
        lastName &&
        birthday &&
        birthday.day &&
        birthday.month &&
        birthday.year &&
        country &&
        city &&
        zipCode &&
        province &&
        address &&
        instagram &&
        numberOfInstagramFollowers &&
        email &&
        phone
      ) {
        try {
          setIsSubmitting(true);
          const { day, month, year } = birthday;
          const birthdayFormatted = `${year}/${("0" + Number(month)).slice(
            -2
          )}/${("0" + Number(day)).slice(-2)}`;
          const addressFormatted = {
            addr1: address,
            city,
            state: province,
            zip: zipCode,
            country,
          };
          const formattedWebsite =
            website.startsWith("http") || !website
              ? website
              : `http://${website}`;

          const body = {
            email,
            firstName,
            lastName,
            birthday: birthdayFormatted,
            listId: content.listId,
            shouldConfirm: false,
            address: addressFormatted,
            phone,
            instagram,
            numberOfInstagramFollowers,
            blog,
            website: formattedWebsite,
            youtube,
            tiktok,
          };
          const response = await fetch(
            "/.netlify/functions/newsletter-subscription",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
          const data = await response.json();
          if (!response.ok) {
            switch (data.reason) {
              case "ALREADY_REGISTERED":
                setSubscriptionResult("ALREADY_REGISTERED");
                break;
              default:
                setSubscriptionResult("ERROR");
                break;
            }
          } else {
            await fetch("/.netlify/functions/subscribe-customer", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, firstName, lastName }),
            });
            switch (data.msg) {
              case "SUBSCRIBED":
                setSubscriptionResult("SUBSCRIBED");
                break;
              case "PENDING":
                setSubscriptionResult("PENDING");
                break;
              default:
                setSubscriptionResult("SUCCESS");
                break;
            }
          }
        } catch (err) {
          setSubscriptionResult("ERROR");
        } finally {
          setIsSubmitting(false);
        }
      }
    } else {
      setPrivacyError(true);
    }
  }

  const checkboxId = useMemo(() => uniqueId(), []);

  return (
    <Container>
      <Title>{content.title}</Title>
      {content.textNode && (
        <Text
          dangerouslySetInnerHTML={{
            __html: content.textNode.childMarkdownRemark.html,
          }}
        />
      )}
      {!subscriptionResult && (
        <>
          <SignUpForm onSubmit={submitPreprocessing}>
            <Label>
              <FormattedMessage id="label.firstName" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.firstName" })}
              type="text"
              onChange={(e) =>
                setFormState({ ...formState, firstName: e.target.value })
              }
              value={formState.firstName}
            />
            <Label>
              <FormattedMessage id="label.lastName" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.lastName" })}
              type="text"
              onChange={(e) =>
                setFormState({ ...formState, lastName: e.target.value })
              }
              value={formState.lastName}
            />
            <Label>
              <FormattedMessage id="label.birthday" />
            </Label>
            <DateContainer>
              <DateBlock>
                <Label>
                  <FormattedMessage id="label.birthday.day" />
                </Label>
                <Select
                  placeholder={formatMessage({
                    id: "label.birthday.day",
                  })}
                  value={formState.birthday.day}
                  options={[...Array(31).keys()].map((index) => index + 1)}
                  useSystemSelect={true}
                  display={(value) => value}
                  valueChange={(value) => {
                    setFormState({
                      ...formState,
                      birthday: {
                        ...formState.birthday,
                        day: Number(value),
                      },
                    });
                  }}
                  compare={(value, option) => value === option}
                  systemSelectStyleOverride={{
                    letterSpacing: "0.08em",
                    color: theme.colors.text,
                  }}
                />
              </DateBlock>
              <DateBlock>
                <Label>
                  <FormattedMessage id="label.birthday.month" />
                </Label>
                <Select
                  placeholder={formatMessage({
                    id: "label.birthday.month",
                  })}
                  value={formState.birthday.month}
                  options={[...Array(12).keys()].map((index) => index + 1)}
                  useSystemSelect={true}
                  display={(value) => value}
                  valueChange={(value) =>
                    setFormState({
                      ...formState,
                      birthday: {
                        ...formState.birthday,
                        month: Number(value),
                      },
                    })
                  }
                  compare={(value, option) => value === option}
                  systemSelectStyleOverride={{
                    letterSpacing: "0.08em",
                    color: theme.colors.text,
                  }}
                />
              </DateBlock>
              <DateBlock>
                <Label>
                  <FormattedMessage id="label.birthday.year" />
                </Label>
                <Select
                  placeholder={formatMessage({
                    id: "label.birthday.year",
                  })}
                  value={formState.birthday.year}
                  options={[...Array(100).keys()].map((index) => 2020 - index)}
                  useSystemSelect={true}
                  display={(value) => value}
                  valueChange={(value) =>
                    setFormState({
                      ...formState,
                      birthday: {
                        ...formState.birthday,
                        year: Number(value),
                      },
                    })
                  }
                  compare={(value, option) => value === option}
                  systemSelectStyleOverride={{
                    letterSpacing: "0.08em",
                    color: theme.colors.text,
                  }}
                />
              </DateBlock>
            </DateContainer>
            <Label>
              <FormattedMessage id="label.country" />
            </Label>
            <Select
              placeholder={formatMessage({
                id: "label.country",
              })}
              value={formState.country}
              useSystemSelect={true}
              options={countries}
              display={(value) => value.name}
              valueChange={(value) =>
                setFormState({
                  ...formState,
                  country: value.code,
                  province:
                    (value.provinces &&
                      value.provinces.length > 0 &&
                      value.provinces[0].code) ||
                    "",
                })
              }
              compare={(value, option) =>
                value === option.code || value === option.name
              }
              systemSelectStyleOverride={{
                letterSpacing: "0.08em",
                color: theme.colors.text,
              }}
            />
            <Label>
              <FormattedMessage id="label.city" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.city" })}
              type="text"
              onChange={(e) =>
                setFormState({ ...formState, city: e.target.value })
              }
              value={formState.city}
            />
            <Label>
              <FormattedMessage id="label.zip" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.zip" })}
              type="text"
              onChange={(e) =>
                setFormState({ ...formState, zipCode: e.target.value })
              }
              value={formState.zipCode}
            />
            {provinces && provinces.length > 0 && (
              <>
                <Label>
                  <FormattedMessage id="label.province" />
                </Label>
                <Select
                  placeholder={formatMessage({
                    id: "label.province",
                  })}
                  value={formState.province}
                  disabled={provinces.length === 0}
                  options={provinces}
                  useSystemSelect={true}
                  display={(value) => value.name}
                  valueChange={(value) =>
                    setFormState({ ...formState, province: value.code })
                  }
                  compare={(value, option) =>
                    value === option.code || value === option.name
                  }
                  systemSelectStyleOverride={{
                    letterSpacing: "0.08em",
                    color: theme.colors.text,
                  }}
                />
              </>
            )}
            <Label>
              <FormattedMessage id="label.address" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.address" })}
              type="text"
              onChange={(e) =>
                setFormState({ ...formState, address: e.target.value })
              }
              value={formState.address}
            />
            <Label>
              <FormattedMessage id="label.instagram" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.instagram" })}
              type="text"
              value={formState.instagram}
              onChange={(e) =>
                setFormState({ ...formState, instagram: e.target.value })
              }
            />

            <Label>
              <FormattedMessage id="label.numberOfInstagramFollowers" />
            </Label>
            <Select
              placeholder={formatMessage({
                id: "label.numberOfInstagramFollowers",
              })}
              value={formState.numberOfInstagramFollowers}
              options={[
                "5.000 - 25.000",
                "25.000 - 50.000",
                "50.000 - 100.000",
                "100.000+",
              ]}
              useSystemSelect={true}
              display={(value) => value}
              valueChange={(value) =>
                setFormState({
                  ...formState,
                  numberOfInstagramFollowers: value,
                })
              }
              compare={(value, option) => value === option}
              systemSelectStyleOverride={{
                letterSpacing: "0.08em",
                color: theme.colors.text,
              }}
            />
            <Label>
              <span>Email</span>
            </Label>
            <Input
              placeholder="Email"
              type="email"
              siqatrib="email"
              onChange={(e) =>
                setFormState({ ...formState, email: e.target.value })
              }
              value={formState.email}
            />
            <Label>
              <FormattedMessage id="label.phone" />
            </Label>
            <Input
              placeholder={formatMessage({ id: "label.phone" })}
              type="text"
              value={formState.phone}
              onChange={(e) =>
                setFormState({ ...formState, phone: e.target.value })
              }
            />
            <Label>
              <FormattedMessage id="label.blog" />
            </Label>
            <Input
              placeholder={formatMessage({
                id: "label.blog",
              })}
              type="text"
              value={formState.blog}
              onChange={(e) =>
                setFormState({ ...formState, blog: e.target.value })
              }
            />
            <Label>
              <FormattedMessage id="label.website" />
            </Label>
            <Input
              placeholder={formatMessage({
                id: "label.website",
              })}
              type="text"
              value={formState.website}
              onChange={(e) =>
                setFormState({ ...formState, website: e.target.value })
              }
            />
            <Label>
              <FormattedMessage id="label.youtube" />
            </Label>
            <Input
              placeholder={formatMessage({
                id: "label.youtube",
              })}
              type="text"
              value={formState.youtube}
              onChange={(e) =>
                setFormState({ ...formState, youtube: e.target.value })
              }
            />
            <Label>
              <FormattedMessage id="label.tiktok" />
            </Label>
            <Input
              placeholder={formatMessage({
                id: "label.tiktok",
              })}
              type="text"
              value={formState.tiktok}
              onChange={(e) =>
                setFormState({ ...formState, tiktok: e.target.value })
              }
            />
            <SignUpButton
              disabled={
                !formState.firstName ||
                !formState.lastName ||
                !formState.birthday ||
                !formState.birthday.day ||
                !formState.birthday.month ||
                !formState.birthday.year ||
                !formState.email ||
                !formState.country ||
                !formState.city ||
                !formState.zipCode ||
                !formState.province ||
                !formState.address ||
                !formState.phone ||
                !formState.instagram ||
                !formState.numberOfInstagramFollowers
              }
              role="submit"
            >
              {!isSubmitting ? (
                <FormattedMessage id="label.signup" />
              ) : (
                <Spinner />
              )}
            </SignUpButton>
          </SignUpForm>
          <Checkbox hasError={privacyError}>
            <input
              id={checkboxId}
              type="checkbox"
              checked={privacy}
              onChange={() => {
                setPrivacy((oldState) => !oldState);
                setPrivacyError(false);
              }}
            />
            <label
              htmlFor={checkboxId}
              dangerouslySetInnerHTML={{
                __html: content.privacyTextNode.childMarkdownRemark.html,
              }}
            />
          </Checkbox>
        </>
      )}

      {subscriptionResult === "SUBSCRIBED" && (
        <SuccessContainer>
          <FormattedMessage id="newsletterFormOnlineOffline.success" />
        </SuccessContainer>
      )}
      {subscriptionResult === "PENDING" && (
        <SuccessContainer>
          <FormattedMessage id="newsletter.pending" />
        </SuccessContainer>
      )}
      {subscriptionResult === "ALREADY_REGISTERED" && (
        <AlreadyRegisteredContainer>
          <FormattedMessage id="newsletter.alreadyRegistered" />
        </AlreadyRegisteredContainer>
      )}
      {subscriptionResult === "ERROR" && (
        <ErrorContainer>
          <FormattedMessage id="newsletter.error" />
        </ErrorContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 600px;
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (max-width: 700px) {
    width: 100%;
    padding: 60px 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Text = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

const SuccessContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
`;

const ErrorContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.error};
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-left: 20px;
    margin-right: 20px;
  }
  div:first-child {
    margin-left: 0;
  }
  div:last-child {
    margin-right: 0;
  }
`;

const DateBlock = styled.div`
  flex: 1;
`;

const AlreadyRegisteredContainer = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 15px;
  font-weight: 600;
`;

const SignUpButton = styled(Button)<{ disabled: boolean; role: string }>`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

const SignUpForm = styled.form`
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
`;

const Input = styled((props) => <input {...props} />)`
  background-color: #f7f7f7;
  border: none;
  flex-grow: 2;
  padding: 10px 20px;
  font-family: soleil, sans-serif;
  border: 1px solid #c6c6c6;
  font-size: 13px;
  letter-spacing: 0.08em;
  color: #333;
  font-weight: 400;
  height: 50px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-family: soleil, sans-serif;
  font-size: 13px;
  letter-spacing: 0.08em;
  color: #333;
  padding-bottom: 8px;
`;

const Checkbox = styled.div<{ hasError: boolean }>`
  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ theme, hasError }) =>
        hasError &&
        css`
          border: 2px solid ${theme.colors.error};
        `}
    }
    p {
      margin: 0;
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      span {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;
        &:hover {
          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`;

export default FormInfluencer;
