import { GatsbyImage } from "gatsby-plugin-image";
import useComponentSize from "hooks/useComponentSize";
import React, { useRef } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "react-carousel";
import styled from "styled-components";
import { PressSliderRecord } from "templates/page";
import CircleArrowLeft from "../icons/CircleArrowLeft";
import CircleArrowRight from "../icons/CircleArrowRight";
import Wrapper from "../Wrapper";

interface Props {
  content: PressSliderRecord;
}

const PressSlider: React.SFC<Props> = ({ content }) => {
  const ref = useRef(null);
  const size = useComponentSize(ref);

  const { title, images } = content;

  const visibleSlides =
    size.width < 400
      ? 1
      : size.width < 600
      ? 2
      : size.width < 800
      ? 3
      : size.width < 1000
      ? 4
      : 5;

  return (
    <Background>
      <Container>
        <Wrapper>
          <PressSliderContainer ref={ref}>
            <Title>{title}</Title>
            <CarouselProvider
              totalSlides={images.length}
              visibleSlides={visibleSlides}
            >
              <Slider>
                {images.map((slide, index) => (
                  <Slide key={index} index={index}>
                    <Image
                      image={slide.gatsbyImageData}
                      alt={slide.alt || ""}
                    />
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
            </CarouselProvider>
          </PressSliderContainer>
        </Wrapper>
      </Container>
    </Background>
  );
};
const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.background2};
`;

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  position: relative;
  @media (max-width: 1280px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
`;

const PressSliderContainer = styled.div`
  position: relative;
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  @media (max-width: 860px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const Image = styled(GatsbyImage)`
  width: 100%;
`;

export default PressSlider;
