import Link from "components/Link";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import useComponentSize from "hooks/useComponentSize";
import { useLocale } from "hooks/useLocale";
import React, { useRef } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "react-carousel";
import styled from "styled-components";
import { NewsSliderRecord } from "templates/page";
import CircleArrowLeft from "../icons/CircleArrowLeft";
import CircleArrowRight from "../icons/CircleArrowRight";
import Wrapper from "../Wrapper";

interface Props {
  content: NewsSliderRecord;
}

interface LocalizedNews {
  handle: string;
  title: string;
  featuredImage: { gatsbyImageData: IGatsbyImageData; alt?: string };
}

interface StaticQuery {
  itNews: {
    nodes: LocalizedNews[];
  };
  enNews: {
    nodes: LocalizedNews[];
  };
  esNews: {
    nodes: LocalizedNews[];
  };
}

const NewsSlider: React.SFC<Props> = ({ content }) => {
  const { locale, defaultLocale } = useLocale();
  // Refs
  const ref = useRef(null);
  const size = useComponentSize(ref);

  const { itNews, enNews, esNews } = useStaticQuery<StaticQuery>(staticQuery);
  let news: LocalizedNews[];
  switch (locale) {
    case "it":
      news = itNews.nodes;
      break;
    case "en":
      news = enNews.nodes;
      break;
    case "es":
      news = esNews.nodes;
      break;
    default:
      news = [];
  }

  const visibleSlides = size.width < 600 ? 1 : size.width < 900 ? 2 : 3;

  if (visibleSlides > news.length) {
    return null;
  }

  return (
    <Background>
      <Container>
        <Wrapper>
          <Heading>{content.title}</Heading>
          <PressSliderContainer ref={ref}>
            <CarouselProvider
              totalSlides={news.length}
              visibleSlides={visibleSlides}
            >
              <Slider>
                {news.map((slide, index) => (
                  <Slide key={index} index={index}>
                    <SlideWrapper>
                      <Link
                        to={`${
                          locale === defaultLocale ? `` : `/${locale}`
                        }/news/${slide.handle}/`}
                      >
                        <ImageContainer>
                          <Image
                            image={slide.featuredImage.gatsbyImageData}
                            alt={slide.featuredImage.alt || ""}
                          />
                        </ImageContainer>
                        <Title>{slide.title}</Title>
                      </Link>
                    </SlideWrapper>
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
              <DotGroup />
            </CarouselProvider>
          </PressSliderContainer>
        </Wrapper>
      </Container>
    </Background>
  );
};

const staticQuery = graphql`
  {
    itNews: allDatoCmsNews(
      filter: { locale: { eq: "it" }, title: { ne: null } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        title
        featuredImage {
          alt
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    enNews: allDatoCmsNews(
      filter: { locale: { eq: "en" }, title: { ne: null } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        title
        featuredImage {
          alt
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    esNews: allDatoCmsNews(
      filter: { locale: { eq: "es" }, title: { ne: null } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        handle
        title
        featuredImage {
          alt
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.background2};
`;

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;
  position: relative;
  @media (max-width: 1280px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
`;

const PressSliderContainer = styled.div`
  position: relative;
  button.carousel__back-button,
  button.carousel__next-button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
  button.carousel__back-button {
    left: -40px;
  }
  button.carousel__next-button {
    right: -40px;
  }
  .carousel__dot-group {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
    .carousel__dot {
      font-size: 0;
      line-height: 0;
      display: inline-block;
      padding: 8px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &::before {
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 3px;
        display: block;
        background-color: ${({ theme }) => theme.colors.main};
        opacity: 0.5;
        transition: 0.3s all;
      }
      &.carousel__dot--selected {
        &::before {
          opacity: 1;
        }
      }
    }
  }
`;

const Heading = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  @media (max-width: 860px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const SlideWrapper = styled.div`
  margin: 15px;
  position: relative;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const ImageContainer = styled.div`
  height: 500px;
  position: relative;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 400px;
  }
`;

const Image = styled(GatsbyImage)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`;

const Title = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.textLight};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  line-height: 1.2em;
  position: absolute;
  bottom: 0;
  padding: 20px;
  padding-top: 100px;
  width: 100%;
  background: rgb(0, 0, 0, 0.5);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export default NewsSlider;
