import React, { SFC, useEffect, useRef } from "react";
import { DatoCmsScript } from "templates/page";

interface Props {
  data: DatoCmsScript;
}

const ScriptComponent: SFC<Props> = ({ data }) => {
  const { scriptParameters } = data;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script") as any;
    Object.entries(JSON.parse(scriptParameters)).forEach(([key, value]) => {
      script[key] = value;
    });
    ref.current && ref.current.appendChild(script);
    return () => {
      ref.current && ref.current.removeChild(script);
    };
  }, []);

  return <div ref={ref} />;
};

export default ScriptComponent;
